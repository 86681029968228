import React, { useState, useEffect } from "react";
import {
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Button,
  Divider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "src/pages/config";
import { useLocation } from "react-router-dom";
import "./Payment.css";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function PendingReceipt() {
  const [userName, setUserName] = useState([]);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userName"));
    if (items) {
      setUserName(items);
    }
  }, []);
  const { state } = useLocation();

  const [transaction, setTransaction] = useState("CASH");
  const [staff, setStaff] = useState("Admin");
  const [loading, setLoading] = useState(false);
  const [voucherNo, setVoucherNo] = useState(null);
  const [date, setDate] = useState();
  const [getStaff, setGetStaff] = useState([]);
  const [cBalance, setCbalance] = useState(0);
  const [payNow, setPayNow] = useState(0);
  const [newBalance, setNewBalance] = useState(0);
  const [comInfo, setComInfo] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [recData, setRecData] = useState({
    voucherNo: null,
    recdate: null,
    cust_id: state.cust_id,
    name: state.name,
    transMode: null,
    payAmount: 0,
    chequeNo: null,
    narration: "",
    staffName: null,
    netTotal: null,
  });
  const [snack, setSnack] = useState({
    typ: "",
    message: "",
  });

  const [hideBtn, setHideBtn] = useState(false);
  const navigate = useNavigate();
  const handleChange = (event) => {
    setTransaction(event.target.value);
  };
  const handleStaffChange = (event) => {
    setStaff(event.target.value);
  };
  const handleinputChange = (event) => {
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newData = { ...recData };
    newData[fieldName] = fieldValue;
    setRecData(newData);
  };

  const paymentNow = (e) => {
    setPayNow(e.target.value);

    const balanceNew = cBalance - e.target.value;
    setNewBalance(balanceNew);
  };
  dayjs.extend(customParseFormat);

  useEffect(() => {
    async function fetchData() {
      await axios
        .get(baseUrl + "/api/getVoucherEmployee")
        .then((res) => {
          if (res.data[0][0].voucher === null) {
            setVoucherNo(1);
          } else {
            setVoucherNo(res.data[0][0].voucher);
          }
          setGetStaff(res.data[1]);
          setCbalance(state.balance);
          setNewBalance(state.balance);
        })
        .catch((err) => {
          if (err.response.status === 403)
            navigate("/login", { replace: true });
        });
    }

    async function getCompany() {
      await axios.get(baseUrl + "/api/company").then((res) => {
        setComInfo(res.data[0]);
      });
    }
    fetchData();
    getCompany();
  }, []);

  const dateChange = (newDate) => {
    setDate(newDate);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleClickOpen = () => {
    if (payNow != 0) {
      setHideBtn(true);
      recData.voucherNo = voucherNo;
      recData.transMode = transaction;
      recData.payAmount = payNow;
      recData.staffName = staff;
      recData.recdate = date;
      recData.netTotal = parseFloat(state.netTotal).toFixed(2);
      axios({
        method: "post",
        url: baseUrl + "/api/addPayment",
        data: recData,
      })
        .then((res) => {
          if (res.data === true) {
            const evtType = state.event_typ;
            const custName = state.name;
            const smsMobile = state.mobile;
            const paidAmount = payNow;
            const smsBalance = parseFloat(newBalance).toFixed(2);
            const smsUrl =
              "https://sapteleservices.com/SMS_API/sendsms.php?username=centuryauditorium&password=fe8cf8&mobile=" +
              smsMobile +
              "&sendername=CNRYAD&message=Dear " +
              custName +
              ",  received " +
              paidAmount +
              " towards booking for " +
              evtType +
              ". Balance amount " +
              smsBalance +
              ". Thanks Century auditorium&routetype=2";
            // "http://sms.rbsindia.net/api/sms?format=xml&key=f0e290461b7636490d3c76bf10da86ff&method=MT&mobile="+smsMobile+"&sender=CNRYAD&route=TL&pe_id=1201167168496451916&pe_template_id=1207168180190180599&text=Dear "+custName+", received "+payNow+" towards booking for "+evtType+". Balance amount "+smsBalance+". Thanks Century auditorium";
            axios.get(smsUrl);

            // setHideBtn(true);
            // setLoading(true)
            setSnack({
              typ: "success",
              message: "Receipt Saved ",
            });
            setOpen(true);
          } else {
            setHideBtn(false);
            setSnack({
              typ: "error",
              message: "Receipt Failed ",
            });
            setOpen(true);
          }
        })
        .catch((err) => {
          if (err.response.status === 403)
            navigate("/login", { replace: true });
        });
    } else {
      setOpen(true);
      setSnack({
        typ: "warning",
        message: "Amount cannot be empty ",
      });
    }
    // navigate(-1);
  };
  var localizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormat);

  return (
    <>
      {/* <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}  style={{ bottom: "24px", left: "auto", right: "24px" }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Add Amount
        </Alert>
      </Snackbar> */}
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        style={{ bottom: "24px", left: "auto", right: "24px" }}
      >
        <Alert
          onClose={handleClose}
          severity={snack.typ}
          sx={{ width: "100%" }}
        >
          {snack.message}
        </Alert>
      </Snackbar>
      <div>
        <div className="container">
          <div className="recieBody">
            <div className="recieheader">
              <div className="recieLogo">
                {" "}
                <img src="/static/CompanyLogo.png" alt="" />
              </div>
              <div className="recieInfo">
                <p>{comInfo.Add1}</p>
                <p>{comInfo.Place}</p>
                <p>
                  {comInfo.Ph}, {comInfo.Country}
                </p>
                <p>{comInfo.Web}</p>
                <p>{comInfo.EMail}</p>
                <p>GSTIN : {comInfo.Add2}</p>
              </div>
            </div>
            <hr />
            <div className="typeRecei">
              <h3>Receipt </h3>
            </div>
            <div>
              <div className="recei_content_header">
                <TextField
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="standard"
                  name="voucherNo"
                  label="Receipt No "
                  value={voucherNo}
                  InputLabelProps={{ shrink: true }}
                ></TextField>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date"
                    inputFormat="dddd DD/MM/YYYY"
                    value={date}
                    onChange={dateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        margin="dense"
                        variant="standard"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="paymentBody">
                <div className="doubleLine">
                  <FormControl>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      variant="standard"
                      name="cust_id"
                      label="Customer ID"
                      value={state.cust_id}
                    ></TextField>
                  </FormControl>
                  <FormControl>
                    <TextField
                      style={{ color: "black" }}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      variant="standard"
                      name="name"
                      label="Customer Name"
                      value={state.name}
                    ></TextField>
                  </FormControl>
                </div>
                <div className="doubleLine">
                  <FormControl variant="standard" sx={{ minWidth: 150 }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Transaction Mode
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={transaction}
                      onChange={handleChange}
                      label="Transaction Mode"
                      name="transMode"
                      defaultValue={"cash"}
                    >
                      <MenuItem value="CASH"> Cash</MenuItem>
                      <MenuItem value="UPI">UPI</MenuItem>
                      <MenuItem value="UPI">GPAY</MenuItem>
                      <MenuItem value="CARD">Card</MenuItem>
                      <MenuItem value="CHEQUE">Cheque</MenuItem>
                      <MenuItem value="CHEQUE">RTGS</MenuItem>
                      <MenuItem value="CHEQUE">NEFT</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <TextField
                      type="number"
                      variant="standard"
                      name="payAmount"
                      value={payNow}
                      onChange={paymentNow}
                      label="Amount Paying"
                    ></TextField>
                  </FormControl>
                </div>
                <FormControl>
                  <TextField
                    type="number"
                    variant="standard"
                    name="chequeNo"
                    label="Cheque Number"
                    onChange={handleinputChange}
                    value={recData.chequeNo}
                  ></TextField>
                </FormControl>
                <FormControl>
                  <TextField
                    type="text"
                    variant="standard"
                    name="narration"
                    label="Narration"
                    onChange={handleinputChange}
                    value={recData.narration}
                  ></TextField>
                </FormControl>
              </div>
              <Divider style={{ paddingTop: "1rem" }} />
              <div className="paymentFooter">
                <FormControl
                  variant="standard"
                  sx={{ minWidth: 150 }}
                  className="staffname"
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Staff Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={staff}
                    onChange={handleStaffChange}
                    label="Transaction Mode"
                    name="staffName"
                  >
                    {getStaff.map((getStaff) => (
                      <MenuItem value={getStaff.Name} key={getStaff.Name}>
                        {getStaff.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="amountSection">
                  <h3>
                    Total Amount :{" "}
                    <span>{parseFloat(state.netTotal).toFixed(2)}</span>
                  </h3>
                  <h3>
                    Current Balance :{" "}
                    <span>{parseFloat(cBalance).toFixed(2)}</span>
                  </h3>
                  <Divider className="payDivider" />
                  <h3>
                    Balance : <span>{parseFloat(newBalance).toFixed(2)}</span>
                  </h3>
                </div>
              </div>
            </div>
            {/* <Button variant="outlined" color="error">
        <Link to = "/dashboard/payment" style={{color : "red", textDecoration: "none"}}>
        Back
        </Link>
      </Button> */}
            <LoadingButton
              loading={loading}
              variant="contained"
              color="primary"
              style={
                hideBtn === true ? { display: "none" } : { display: "block" }
              }
              onClick={handleClickOpen}
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default PendingReceipt;
