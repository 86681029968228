import React, { useEffect, useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Card } from "@mui/material";
// components
import Page from "../components/Page";
import FlagIcon from "@mui/icons-material/Flag";
import AddchartIcon from "@mui/icons-material/Addchart";
import InsightsIcon from "@mui/icons-material/Insights";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from "../sections/@dashboard/app";
import axios from "axios";
import { baseUrl } from "./config";
import { useNavigate, generatePath } from "react-router-dom";

export default function DashboardApp() {
  // const theme = useTheme();
  const navigate = useNavigate();
  const [allEvent, setAllEvent] = useState();
  const [upcoming, setUpcoming] = useState();

  useEffect(() => {
    async function Dashboard() {
      await axios
        .get(baseUrl + "/api/getDashboardCounts")
        .then((res) => {
          setAllEvent(res.data[0][0].allBooking);
          setUpcoming(res.data[1][0].pending);
        })
        .catch((err) => {
          if (err.response.status === 403)
            navigate("/login", { replace: true });
        });
    }
    Dashboard();
  }, [0]);

  function goToPage(page) {
    navigate(page, { replace: true });
  }

  function test() {
    let id = 10;
   
  }

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <Card
              className="dashCard"
              onClick={() => goToPage("/dashboard/booking")}
            >
            
              <AppWidgetSummary
                title="Total Bookings"
                total={allEvent}
                icon={"ri:function-fill"}
              />
            </Card>
          </Grid >

          <Grid item xs={12} sm={6} md={6}>
            <Card onClick={() => goToPage("/dashboard/upcoming")}>
              <AppWidgetSummary
                title="Upcoming Events"
                total={upcoming}
                color="info"
                icon={"material-symbols:event-upcoming"}
              />
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card onClick={() => test()}>
              {/* <AppWidgetSummary
                title="Item Orders"
                total={1723315}
                color="warning"
                icon={"ant-design:windows-filled"}
              /> */}
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card>
              {/* <AppWidgetSummary
                title="Bug Reports"
                total={234}
                color="error"
                icon={"ant-design:bug-filled"}
              /> */}
            </Card>
          </Grid>

          <Grid item xl={12} md={12} lg={12} xs={12}>
            <Card style={{ background: "#ccc7ef", height: 250 }}></Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
