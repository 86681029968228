import React, { useState, useEffect } from "react";
import { TextField, Box } from "@mui/material";
import axios from "axios";
import { baseUrl } from "src/pages/config";

const CancelForm = ({ bookingId, cancelData }) => {
  const [cancelDetails, setDeleteDetails] = useState({
    book_id: bookingId,
    name: null,
    address: null,
    phone: null,
    reason: "",
    refund: 0,
    cust_id: null,
    voucher: null,
    cust_name: "",
    net_total: 0,
  });
  const [netTotal, setNetTotal] = useState(0);
  const [paid, setPaid] = useState(0);
  const [custName, setCustName] = useState("");
  const [voucher, setVoucher] = useState(null);

  useEffect(() => {
    function passId() {
      axios({
        method: "post",
        url: baseUrl + "/api/getBookCancelMaster",
        data: { cust_id: bookingId },
      }).then((res) => {
      
        setNetTotal(res.data[0][0].net_total);
        setPaid(res.data[0][0].amount);
        setCustName(res.data[0][0].cust_name);
        setVoucher(res.data[1][0].voucher);
      });
    }
    passId();
  }, []);
  const handleDeleteDetail = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newData = { ...cancelDetails };
    newData[fieldName] = fieldValue;
    newData.cust_id = bookingId;
    newData.voucher = voucher;
    newData.cust_name = custName;

    newData.net_total = netTotal;

    setDeleteDetails(newData);
    cancelData(newData);

  };
  const style = {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    gap: "10px",
    padding: "16px 0",
  };
  return (
    <Box style={style} component="form">
      <TextField
        fullWidth
        id="name"
        className="textField"
        size="small"
        type="text"
        label="Customer Name"
        name="name"
        variant="outlined"
        value={cancelDetails.name}
        onChange={handleDeleteDetail}
        required
      />
      <TextField
        fullWidth
        label="Phone Number"
        size="small"
        name="phone"
        value={cancelDetails.phone}
        onChange={handleDeleteDetail}
        type="number"
        variant="outlined"
        required
      ></TextField>
      <TextField
        fullWidth
        className="textField"
        size="small"
        type="text"
        label="Address"
        name="address"
        variant="outlined"
        value={cancelDetails.address}
        onChange={handleDeleteDetail}
      />
      <TextField
        id="outlined-multiline-flexible"
        label="Remarks"
        multiline
        maxRows={6}
        name="reason"
        className="custSugg textField"
        value={cancelDetails.reason}
        onChange={handleDeleteDetail}
      />
      <Box sx={{ width: "100%", textAlign: "right" }}>
        <h5>
          Net Total : <span>{netTotal}</span>
        </h5>
        <h5>
          Amount Paid : <span>{paid}</span>
        </h5>
        <TextField
          id="name"
          className="textField"
          type="number"
          label="Refund Amount "
          name="refund"
          variant="outlined"
          placeholder="0"
          disabled={paid <= 0}
          value={cancelDetails.refund}
          onChange={handleDeleteDetail}
        />
      </Box>
    </Box>
  );
};

export default CancelForm;
