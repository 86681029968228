import React, { useState } from "react";
import { Card } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { baseUrl } from "src/pages/config";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import NoData from "src/pages/NoData";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

function FeedbackBy() {
  const { state } = useLocation();
  const [feedback, setFeedback] = useState(null);

  const navigate = useNavigate;
  useEffect(() => {
    async function getStaffs() {
      axios({
        method: "post",
        url: baseUrl + "/api/getFeedbackByBookID",
        data: { book_id: state.cust_id },
      })
        .then((res) => {
          if (res.data.length > 0) {
            setFeedback(res.data[0]);
          } else {
           // navigate(-1);
         
          }

        })
        .catch((err) => {
      
          if (err.response.status === 403) navigate("/login", { replace: true });
        });
    }
    getStaffs();
  }, []);
  return (
    <Card sx={{ p: 2 }}>
      {feedback=== null ? (
        <NoData />
      ) : (
      <>
        <div>
          <p>
            Booking ID: <b>{feedback.book_id}</b>{" "}
          </p>
          <p>
            Customer Name: <b>{feedback.name}</b>{" "}
          </p>
          <p>
            Customer Mob: <b>{feedback.mobile}</b>{" "}
          </p>
        </div>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          // defaultValue={feedback.rating}
          value={parseInt(feedback.rating)}
          // value={4}
          IconContainerComponent={IconContainer}
          getLabelText={(value) => customIcons[value].label}
          highlightSelectedOnly
        />
      </>
   )}
    </Card>
  );
}

export default FeedbackBy;
