import React from 'react'

function Logo() {
  return (
    <img
    style={{ width: "3rem" }}
    src="/favicon/favicon.png"
    alt="login"
  />
  )
}

export default Logo