import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { baseUrl } from "../config";
import { Card } from "@mui/material";

const columns = [
  {
    field: "#",
    headerName: "#",
    width: 80,
    renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
  },
  {
    field: "Descri",
    headerName: "Booking ID",
    width: 120,
  },
  {
    field: "Inv_no",
    headerName: "Return No",
    width: 100,
  },
  {
    field: "name",
    headerName: "Party Name",
    width: 170,
  },
  {
    field: "ItemName",
    headerName: "Item Name",
    width: 220,
  },
  {
    field: "Qty",
    headerName: "Missing Qty",
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    width: 100,
  },
  {
    field: "Rate",
    headerName: "Price",
    width: 110,
  },
  {
    field: "Total",
    headerName: "Total Price",
    width: 110,
  },
];

const rows = [{ id: 1, Descri: "asfdas", SiNo: 45, name: "fdsdsf" }];

function VessalTable({ tableData }) {

  return (
    <Card>
      <Box sx={{ height: "70vh", width: "100%" }}>
        <DataGrid
          rows={tableData}
          columns={columns}
          // pageSize={5}
          // rowsPerPageOptions={[5]}
        />
      </Box>
      <p style={{ padding: "10px" }}>
          {" "}
          <b>Total Amount : {Math.round( tableData.reduce((a, v) => (a = a + v.Total), 0))}</b>
        </p>
    </Card>
  );
}

export default VessalTable;
