import { useRef, useState, useEffect } from "react";
// import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  MenuItem,
  Avatar,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import SettingsIcon from "@mui/icons-material/Settings";
// components
import MenuPopover from "../../components/MenuPopover";
import UserPrivillage from "./UserPrivillage";
// mocks_
import account from "../../_mock/account";
import { useNavigate } from "react-router-dom";

import { baseUrl } from "src/pages/config";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);
  const [openDia, setOpenDia] = useState(false);

  const [userName, setUsername] = useState([]);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClicDiakOpen = () => {
    setOpenDia(true);
  };

  const handleDiaClose = () => {
    setOpenDia(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    axios
      .post(baseUrl + "/api/logout")
      .then((res) => {
        if (res.data.data == "success") {
          navigate("/login", { replace: true });
        }
        setOpen(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userName"));
    if (items) {
      setUsername(items);
    }
  }, []);

  return (
    <>
      <div>
        <Dialog
          open={openDia}
          onClose={handleDiaClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Set Privillages For Users"}
          </DialogTitle>
          <Divider sx={{background: "grey"}}/>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
             <UserPrivillage setOpenDia={setOpenDia}/>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            variant="subtitle2"
            noWrap
            style={{ textTransform: "capitalize" }}
          >
            {userName}
          </Typography>
          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography> */}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {userName === "Admin"|| "admin" ? (
          <MenuItem onClick={handleClicDiakOpen}>
            {" "}
            <SettingsIcon /> Settings
          </MenuItem>
        ) : null}
        <Divider sx={{ borderStyle: "solid" }} />

        <MenuItem onClick={handleLogout} sx={{ background: "lightgreen" }}>
          {" "}
          <LogoutRoundedIcon />
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
