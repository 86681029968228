import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button, Card, TextField, Divider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { baseUrl } from "../config";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LedgerPrint from "./LedgerPrint";
import axios from "axios";

function LedgerData() {
  const { state } = useLocation();
  const { id, name, date } = state;

  const [startDate, setStartDate] = useState(dayjs(date));
  const [endDate, setEndDate] = useState(dayjs());
  const [ledData, setLedData] = useState([]);
  const [custInfo, setCustInfo] = useState({});
  const [netTotal,setNetTotal]=useState(0)
  const navigate = useNavigate();

  const startDateChange = (newValue) => {
    setStartDate(newValue);
  };
  const endDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const getLedger = () => {
    const data = {
      sDate: startDate,
      eDate: endDate,
      book_id: id,
    };

    axios({
      method: "post",
      url: baseUrl + "/api/getLedgerByDate",
      data: data,
    })
      .then((res) => {
        setLedData(res.data[0]);

        setCustInfo(res.data[1][0]);
        setNetTotal(res.data[2][0])
      })
      .catch((err) => {
        if (err.response.status === 403) navigate("/login", { replace: true });
      });
  };

  return (
    <>
      <Card style={{ padding: "1rem", background: "#8effcc24" }}>
        <div>
          <p>
            Party Name : <b> {name}</b>
          </p>
          <p>
            Booking ID : <b> {id} </b>{" "}
          </p>
        </div>
        <h5 style={{ padding: "10px" }}>Choose Dates</h5>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="dateSel">
            <MobileDatePicker
              label="Start Date"
              inputFormat="DD/MM/YYYY"
              value={startDate}
              onChange={startDateChange}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <MobileDatePicker
              label="End Date"
              inputFormat="DD/MM/YYYY"
              value={endDate}
              onChange={endDateChange}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <Button variant="contained" size="small" onClick={getLedger}>
              Search
            </Button>
          </div>
        </LocalizationProvider>
      </Card>
      <hr />
      <Card style={{ marginTop: "1rem ", padding: "1rem" }}>
        <LedgerPrint
          ledData={ledData}
          custInfo={custInfo}
          startDate={startDate}
          endDate={endDate}
          netTotal={netTotal}
        />
      </Card>
    </>
  );
}

export default LedgerData;
