import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Card, Button, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../config";
import NoAccess from "../NoAccess";

const columns = [
  {
    field: "id",
    headerName: "SiNo",
    width: 50,
    renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
  },
  { field: "cust_id", headerName: "Booking No", width: 90 },
  { field: "date", headerName: "Event Date", width: 140 },
  { field: "name", headerName: "Customer name", width: 150 },
  { field: "book_date", headerName: "Booking Date", width: 100 },
  { field: "contact1", headerName: "Contact", width: 130 },
  {
    field: "event_typ",
    headerName: "Event Type",
    width: 150,
  },
];

const rows = [
  {
    id: 1,
    cust_id: 1500,
    name: "Jon",
    date: 12 / 12 / 2022,
    contact1: 9633363422,
    event_typ: "Booking",
  },
];

function UpcomingEvents() {
  const navigate = useNavigate();
  const pName = "upcommingEvents";
  const [access, setAccess] = useState(true);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [upcoming, setUpcoming] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("acc"));
    if (items.includes(pName)) {
      setAccess(true);
      function upEvents() {
        axios
          .get(baseUrl + "/api/getUpcomingBookings")
          .then((res) => {
            setUpcoming(res.data);
          })
          .catch((err) => {
            if (err.response.status === 403)
              navigate("/login", { replace: true });
          });
      }
      upEvents();
    } else {
      setAccess(false);
    }
  }, []);
  return (
    <div>
      {!access ? (
        <NoAccess />
      ) : (
        <>
          <div
            style={{
              padding: "10px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h2>Upcoming Events</h2>
              <Typography variant="caption" display="block" gutterBottom>
                Events in 30 days
              </Typography>
            </div>
            {/* <Button
              variant="contained"
              color="primary"
              size="small"
              endIcon={<SendIcon />}
            >
              {" "}
              Send SMS
            </Button> */}
          </div>
          <Card>
            <div style={{ height: "75vh", width: "100%" }}>
              <DataGrid
                rows={upcoming}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5]}
                // checkboxSelection
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRows = rows.filter((row) =>
                    selectedIDs.has(row.id)
                  );

                  setSelectedRows(selectedRows);
                }}
              />
            </div>
          </Card>
        </>
      )}
    </div>
  );
}

export default UpcomingEvents;
