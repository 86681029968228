import React, { useEffect } from "react";
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { baseUrl } from "src/pages/config";
import CompanyLogo from "src/components/Images/CompanyLogo.png";
import "./InvoiceVew.css";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { ToWords } from "to-words";

function InvoiceView() {
  const navigate = useNavigate();
  const [partyInfo, setPartyInfo] = useState([]);
  const [eventItem, setEventItem] = useState([]);
  const [comInfo, setComInfo] = useState([]);
  const { state } = useLocation();
  const { id, typ } = state; // Read values passed on state
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);

  

  useEffect(() => {
    async function InvoiceData() {
      await axios({
        method: "post",
        url: baseUrl + "/api/getBookingByIdPrint",
        data: { id: id },
      })
        .then((res) => {
          if (res.data)
            // setEventData(res.data);
            setPartyInfo(res.data[1][0]);
          setEventItem(res.data[2]);
          setComInfo(res.data[0][0]);
          // setTaxTyp(partyInfo.tax_typ)
          const sumTaxAmount = res.data[2].reduce(
            (acc, curr) => acc + curr.tax_amount,
            0
          );

          // set the totalTaxAmount state to the sum of the tax_amount values
          setTotalTaxAmount(sumTaxAmount);
        })
        .catch((err) => {
          if (err.response.status === 403)
            navigate("/login", { replace: true });
        });
    }

    InvoiceData();
  }, []);
  useEffect(() => {}, [comInfo]);
  const current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;

  const toWords = new ToWords();

  //let words = toWords.convert(500, { currency: true });

  let words = toWords.convert(
    Math.round(partyInfo.total + totalTaxAmount) || 0,
    { currency: true }
  );

  var localizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormat);

  return (
    <div className="whole_body">
      <div className="viewBody">
        <div className="ViewHeader">
          <div className="logoSide">
            <img className="logoImg" src={CompanyLogo} alt="" />
          </div>
          <div className="companySide">
            <p className="companyInformation">{comInfo.Add1}</p>
            <p className="companyInformation">{comInfo.Place}</p>
            <p className="companyInformation">
              {comInfo.Ph}, {comInfo.Country}
            </p>
            <p className="companyInformation">{comInfo.Web}</p>
            <p className="companyInformation">{comInfo.EMail}</p>
            <p className="companyInformation">GSTIN : {comInfo.Add2}</p>
          </div>
        </div>
        <hr />
        <div className="headerName">
          <p>{typ}</p>
        </div>

        <div className="viewContent">
          <div className="partyInfo">
            <div className="baseInfo">
              <p>{partyInfo.name}</p>
              <p>{partyInfo.address1}</p>
              <p>{partyInfo.address2}</p>
              <p>{partyInfo.contact1}</p>
              <p>{partyInfo.contact2}</p>
              {partyInfo.gst_no ? <p> GSTIN:{partyInfo.gst_no}</p> : null}
              {/* <p>{partyInfo.email}</p> */}
            </div>
            <div className="partyContent">
              {partyInfo.invoice_no > 0 ? (
                <p>
                  {typ !== "BOOKING" ? "Invoice No :" : "No : "}{" "}
                  <b>{partyInfo.invoice_no}</b>
                </p>
              ) : (
                ""
              )}

              <p>
                {" "}
                Date :{" "}
                {dayjs(partyInfo.book_date).format(
                  typ !== "BOOKING" ? " DD/MM/YYYY" : "dddd, DD/MM/YYYY"
                )}{" "}
              </p>
              {/* <p>
                {" "}
                Date : {dayjs(partyInfo.book_date).format(
                  "dddd, DD/MM/YYYY"
                )}{" "}
              </p> */}
              {typ !== "BOOKING" ? (
                ""
              ) : (
                <p>
                  {" "}
                  Time : {dayjs(partyInfo.book_date).format("hh:mm:ss A")}{" "}
                </p>
              )}
            </div>
          </div>
          <hr />

          <div className="headerName">
            {typ !== "BOOKING" ? "" : <p>Event Information</p>}
          </div>
          <div className="eventInform">
            <div>
              <p>
                Event Type : <span>{partyInfo.event_typ}</span>
              </p>
              {partyInfo.guest_count !== null ? (
                <p>
                  Guest No : <span>{partyInfo.guest_count}</span>
                </p>
              ) : (
                " "
              )}

              {typ !== "BOOKING" ? (
                ""
              ) : (
                <p>
                  Event Date :{" "}
                  <span style={{ fontWeight: 700 }}>
                    {dayjs(partyInfo.event_date).format("dddd DD/MM/YYYY") ===
                    dayjs(partyInfo.event_end_date).format(
                      "dddd DD/MM/YYYY"
                    ) ? (
                      <>
                        {dayjs(partyInfo.event_date).format("dddd DD/MM/YYYY")}
                      </>
                    ) : (
                      <>
                        {dayjs(partyInfo.event_date).format("dddd DD/MM/YYYY")}{" "}
                        to{" "}
                        {dayjs(partyInfo.event_end_date).format(
                          "dddd DD/MM/YYYY"
                        )}
                      </>
                    )}
                  </span>
                </p>
              )}

              {typ !== "BOOKING" ? (
                ""
              ) : (
                <p>
                  Time :{" "}
                  {dayjs(date + " " + partyInfo.start_time).format(
                    "hh:mm:A"
                  ) !==
                  dayjs(date + " " + partyInfo.end_time).format("hh:mm:A") ? (
                    <>
                      <span style={{ fontWeight: 700 }}>
                        {dayjs(date + " " + partyInfo.start_time).format(
                          "hh:mm:A"
                        )}{" "}
                        to{" "}
                        {dayjs(date + " " + partyInfo.end_time).format(
                          "hh:mm:A"
                        )}
                      </span>
                    </>
                  ) : (
                    <span style={{ fontWeight: 700 }}>
                      {dayjs(date + " " + partyInfo.start_time).format(
                        "hh:mm:A"
                      )}
                    </span>
                  )}
                </p>
              )}
            </div>
            <br />
            {partyInfo.bride_add !== null || partyInfo.groom_add !== null ? (
              <div id="brideGroom">
                <div>
                  <p>Bride's name and address:</p>
                  <p> {partyInfo.bride_add} </p>
                </div>
                <br />
                <div>
                  <p>Groom's name and address :</p>
                  <p> {partyInfo.groom_add}</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <hr />
          <div className="contentTable">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 500 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>SiNo</TableCell>
                    <TableCell>Item</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="center">Price</TableCell>
                    {partyInfo.tax_typ !== "none" ? (
                      <>
                        <TableCell align="center">Tax </TableCell>
                        <TableCell align="center">Tax Amount </TableCell>
                      </>
                    ) : null}
                    <TableCell align="center">Total Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventItem.map((item, index) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.content_name}</TableCell>
                      <TableCell align="center">{item.content_qty}</TableCell>
                      <TableCell align="center">{item.content_price}</TableCell>
                      {partyInfo.tax_typ !== "none" ? (
                        <>
                          <TableCell align="center">{item.item_tax}%</TableCell>
                          <TableCell align="center">
                            {item.tax_amount}
                          </TableCell>
                        </>
                      ) : null}
                      <TableCell align="center">
                        {partyInfo.tax_typ === "none" ? 
                          (item.content_total =
                            item.content_qty * item.content_price):(item.content_total =
                              item.content_qty * item.content_price+item.tax_amount)
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Divider />
          <div className="totalSection">
            <div style={{ width: "50%", height: "100%" }}>
              <p>{words}</p>
            </div>
            {partyInfo.tax_per > 0 ? (
              <div>
                <h4>
                  Subtotal : <span>{partyInfo.sub_total}</span>
                </h4>

                {partyInfo.tax_typ === "CGST" ? (
                  <>
                    <h4>
                      {" "}
                      CGST ( {partyInfo.tax_perc / 2}% ):{" "}
                      {(((partyInfo.sub_total - partyInfo.discount) / 100) *
                        partyInfo.tax_perc) /
                        2}{" "}
                    </h4>{" "}
                    <h4>
                      {" "}
                      SGST ( {partyInfo.tax_perc / 2}%) :{" "}
                      {(((partyInfo.sub_total - partyInfo.discount) / 100) *
                        partyInfo.tax_perc) /
                        2}{" "}
                    </h4>
                  </>
                ) : partyInfo.tax_typ === "IGST" ? (
                  <h4>
                    {" "}
                    IGST ({partyInfo.tax_per}%) :{" "}
                    {((partyInfo.sub_total - partyInfo.discount) / 100) *
                      partyInfo.tax_perc}{" "}
                  </h4>
                ) : partyInfo.tax_typ === "VAT" ? (
                  <h4>
                    {" "}
                    Tax ({partyInfo.tax_per}%) :{" "}
                    {((partyInfo.sub_total - partyInfo.discount) / 100) *
                      partyInfo.tax_perc}{" "}
                  </h4>
                ) : (
                  ""
                )}
                <h4>
                  Grand Total :{" "}
                  <span>{Math.round(partyInfo.total + totalTaxAmount)}</span>
                </h4>
              </div>
            ) : (
              <div>
                <h4>
                  Subtotal : <span>{partyInfo.sub_total}</span>
                </h4>
                <h4>
                  Discount : <span>{partyInfo.discount}</span>
                </h4>
                {partyInfo.tax_typ === "CGST" ? (
                  <>
                    <h4> CGST :{totalTaxAmount / 2} </h4>{" "}
                    <h4> SGST :{totalTaxAmount / 2} </h4>
                  </>
                ) : partyInfo.tax_typ === "IGST" ? (
                  <h4>
                    {" "}
                    IGST ({partyInfo.tax_per}%) : {totalTaxAmount}{" "}
                  </h4>
                ) : partyInfo.tax_typ === "VAT" ? (
                  <h4>
                    {" "}
                    Tax ({partyInfo.tax_per}%) : {totalTaxAmount}{" "}
                  </h4>
                ) : (
                  ""
                )}
                <h4>
                  Grand Total :{" "}
                  <span>{partyInfo.sub_total - partyInfo.discount+totalTaxAmount}</span>
                </h4>
              </div>
            )}
          </div>
          {/* <div className="divider"></div> */}
          <div className="divider_sig"></div>
          {typ !== "BOOKING" ? (
            <div className="signature_section">
              <Divider />
              <div className="signature_section_name">
                For Century Auditorium{" "}
              </div>
              <div className="signature_section_manager">Manager</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
    // </div>
  );
}

export default InvoiceView;
