import React from "react";
import {
  TextField,
  Button,
  Card,
  Autocomplete,
  FormControl,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "./Css/vesselReturn.css";
import VessalTable from "./VessalTable";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import NoAccess from "../NoAccess";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function VesselReturn() {
  const pName = "amenityReturn";
  const [access, setAccess] = useState(true);
  const [inoNo, setInvoNo] = useState();
  const [bookingId, setBookingId] = useState([]);
  const [date, setDate] = React.useState(dayjs(new Date()));
  const [getId, setGetId] = useState();
  const [amenityData, setAmenityData] = useState(null);
  const [totalQty, setTotalQty] = useState(0);
  const [totalprice, setTotalPrice] = useState(0);
  const [submitB, setSubmitB] = useState(false);
  const [tableData, settableData] = useState([]);
  const [btnDisable, setbtnDisable] = useState(false);
  const [searchedId,setsearchedId]=useState();
  const handleChange = (newValue) => {
    setDate(newValue);
  };
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [snack, setSnack] = useState({
    typ: "",
    message: "",
  });

  useEffect(() => {
    function getInvo() {
      const items = JSON.parse(localStorage.getItem("acc"));

      if (items.includes(pName)) {
        setAccess(true);
        axios.get(baseUrl + "/api/getBookingIdInvoice").then((res) => {
          if (res.data[0][0].invoice === null) {
            setInvoNo(1);
          } else {
            setInvoNo(res.data[0][0].invoice);
          }
          setBookingId(res.data[1]);
        });
      } else {
        setAccess(false);
      
      }
    }

    getInvo();
  }, [0]);

  useEffect(() => {
    async function getAllData() {
      await axios
        .get(baseUrl + "/api/getAmenityDamage")
        .then((res) => {
          settableData(res.data);
        })
        .catch((err) => {
          if (err.response.status === 403)
            navigate("/login", { replace: true });
        });
    }
    getAllData();
  }, [amenityData]);

  const getAmenityData = () => {
    const split = getId.split(/(\d+)/); 
  
    setsearchedId(split[1]);
    axios({
      method: "post",
      url: baseUrl + "/api/getamenityByBookId",
      data: { id: split[1] },
    })
      .then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((object) => {
            object.missingQty = 0;
            object.totalPrice = 0;
          });
          setAmenityData(res.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) navigate("/login", { replace: true });
      });
  };

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const columns = [
    { field: "id", headerName: "SiNo", width: 90 },
    {
      field: "name",
      headerName: "Amenity Name",
      width: 180,
    },
    {
      field: "qty",
      headerName: "Actual Qty",
      width: 150,
    },
    {
      field: "missingQty",
      headerName: "Missing",
      type: "number",
      width: 150,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      width: 110,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      type: "number",
      width: 110,
      editable: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.missingQty > 0
              ? params.row.missingQty * params.row.price
              : 0}
          </>
        );
      },
    },
  ];

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const response = amenityData.map((res) => {
      if (res.id === updatedRow.id) {
        res = updatedRow;
      }
      return res;
    });
    setAmenityData(response);

    return updatedRow;
  };

  const sumQty = () => {
    amenityData.forEach((object) => {
      object.totalPrice = object.missingQty * object.price;
    });

    setTotalQty(amenityData.reduce((a, v) => (a = a + v.missingQty), 0));
    setTotalPrice(amenityData.reduce((a, v) => (a = a + v.totalPrice), 0));
    setSubmitB(true);
  };

  const submitTable = () => {
    const splitt = getId.split(" ");

    const datas = {
      invoiceNo: inoNo,
      date: date,
      bookingID: splitt[0],
      totalQty: totalQty,
      totalPrice: totalprice,
      amenityData,
    };

    axios({
      method: "post",
      url: baseUrl + "/api/addAmenityDamage",
      data: datas,
    })
      .then((res) => {
        if (res.data !== false) {
          setbtnDisable(true);
          setSnack({
            typ: "success",
            message: "Added successfully",
          });
          handleClick();
        } else {
          setSnack({
            typ: "error",
            message: "Something error",
          });
          handleClick();
        }
      })
      .catch((err) => {
        if (err.response.status === 403) navigate("/login", { replace: true });
      });
    // setBookingId("null");
    // setDate(null);
    setInvoNo("");
    setAmenityData(null);
    setGetId("");
    setsearchedId("")
    // settableData(null);
  };

  const rows = [{ id: 1, Descri: "asfdas", SiNo: 45, name: "fdsdsf" }];

  const onchangedBookingid = (e, v) => {
    const label = v.label;
    const number = v.title;
    const numString=number.toString()
    const labelTitle = `${numString} ${label}`;
    setGetId(labelTitle);
   
  };
  return (
    <>
      <Snackbar
        style={{ bottom: "24px", left: "auto", right: "24px" }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        //key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity={snack.typ}
          sx={{ width: "100%" }}
        >
          {snack.message}
        </Alert>
      </Snackbar>

      {access === false ? (
        <NoAccess />
      ) : (
        <>
          <h3> Choose Booking ID</h3>
          <Card>
            <div className="headerSection">
              <FormControl>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Invoice No"
                  variant="outlined"
                  value={inoNo}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date"
                  inputFormat="DD/MM/YYYY"
                  value={date}
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField {...params} id="date" size="small" />
                  )}
                />
              </LocalizationProvider>

              <FormControl>
                <Autocomplete
             
                 value={getId==null?"":getId}
                  size="small"
                  id="free-solo-demo"
                  freeSolo
                  sx={{ width: 221 }}
                  // onChange={getBookingId}
                  // options={bookingId.map(
                  //   (option) => String(option.title) + "  " + option.name
                  // )}
                  options={bookingId}
                  // getOptionLabel={(option) => option.title + " " + option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.title} {option.label}
                    </Box>
                  )}
                 
                  onChange={(e, v) => onchangedBookingid(e, v)}
                  renderInput={(params) => (
                    // setGetId(params.inputProps.value),
                    <TextField {...params} label="Party Name" />
                  )}
                />
              </FormControl>
              <FormControl>
                <TextField
                  
                  size="small"
                  id="outlined-basic"
                  label="Booking ID"
                  variant="outlined"
                  value={searchedId === null ? "" : searchedId}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
              <Button variant="contained" onClick={getAmenityData}>
                Search
              </Button>
            </div>

            {amenityData !== null ? (
              <Box sx={{ height: "70vh", width: "100%" }}>
                <DataGrid
                  rows={amenityData}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  processRowUpdate={processRowUpdate}
                />
              </Box>
            ) : (
              ""
            )}

            <div className="saveBtn">
              <div>
                {submitB == false ? (
                  <Button variant="outlined" onClick={sumQty}>
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={submitTable}
                    disabled={btnDisable}
                  >
                    Submit
                  </Button>
                )}
              </div>
              <div>
                <b>Missing Qty: {totalQty}</b>
                <br />
                <b>Total Price : {totalprice}</b>
              </div>
            </div>
          </Card>
          <br />

          <h3>Amenity Return History</h3>
          <Card>
            <VessalTable tableData={tableData} />
          </Card>
        </>
      )}
    </>
  );
}

export default VesselReturn;
