import React, { Suspense, lazy } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "@mui/material";

// import InstructionPrint from "./InstructionPrint";
// import InvoiceView from "./InvoiceView";
import { Link } from "react-router-dom";
import Skelton from "src/pages/Skelton";
import { useNavigate } from "react-router-dom";

const InstructionPrint = lazy(() => import("./InstructionPrint.js"));
const InvoiceView = lazy(() => import("./InvoiceView.js"));

class ComponentToPrint extends React.Component {
  
  render() {
    
    return (
      <>
        <Suspense fallback={<Skelton />}>
          <InvoiceView />
        </Suspense>
        <br />
      </>
    );
  }
}

class Example extends React.Component {
  
  render() {
  
    return (
      <>
        <div style={{ padding: "1rem" }}>
          <Link
            to={-1}
            style={{ textDecoration: "none", paddingRight: "1rem" }}
          >
            {" "}
            Back
          </Link>
          <ReactToPrint
        
            trigger={() => <Button variant="contained">Print</Button>}
            content={() => this.componentRef}
          />
          <ComponentToPrint ref={(el) => (this.componentRef = el)} />
          <Suspense fallback={<Skelton />}>
            <InstructionPrint />
          </Suspense>
        </div>
      </>
    );
  }
}

export default Example;
