import React, { Suspense, lazy,useEffect,useState } from "react";
import { Box, Card } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./css/DataTable.css";
import Skelton from "../Skelton";
import NoAccess from "../NoAccess";
//Datatable Modules
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";

const SalesStatus = lazy(() => import("./Components/SalesStatus.js"));
const CustomerReport = lazy(() => import("./Components/CustomerReport.js"));
const VesselsReport = lazy(() => import("./Components/VesselsReport.js"));
const BookingEnquiryReport = lazy(() =>
  import("./Components/BookingEnquiryReport.js")
);
const FoodFacilityVessels = lazy(() =>
  import("./Components/FoodFacilityVessels.js")
);
const FeedBackReport = lazy(() => import("./Components/FeedBackReport.js"));
const EmployeeReport = lazy(() => import("./Components/EmployeeReport.js"));
const CancelledBookings = lazy(() =>
  import("./Components/CancelledBookings.js")
);
const CooksReport = lazy(() => import("./Components/CooksReport.js"));
const StaffsReport = lazy(() => import("./Components/StaffsReport.js"));

function Reports() {
  const pName = "reports";
  const [access, setAccess] = useState(true);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("acc"));

    if (items.includes(pName)) {
      setAccess(true);
    } else {
      setAccess(false);
    }
  }, []);

  return (
    <>
   
   { !access ? <NoAccess /> :
    <Card>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab label="Customer Report" value="1" />
              <Tab label="Sales Report" value="2" />
              <Tab label="Vessels Report" value="3" />
              <Tab label="Events Report" value="4" />
              <Tab label="Food, Facility, Amenities" value="5" />
              <Tab label="Feedback Report" value="6" />
              <Tab label="Employees Report" value="7" />
              <Tab label="cancelled Bookings" value="8" />
              <Tab label="Cooks Report" value="9" />
              <Tab label="Cleaning Staffs" value="10" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Suspense fallback={<Skelton />}>
              <CustomerReport />
            </Suspense>
          </TabPanel>
          <TabPanel value="2">
            <Suspense fallback={<Skelton />}>
              <SalesStatus />
            </Suspense>
          </TabPanel>
          <TabPanel value="3">
            <Suspense fallback={<Skelton />}>
              <VesselsReport />
            </Suspense>
          </TabPanel>
          <TabPanel value="4">
            <Suspense fallback={<Skelton />}>
              <BookingEnquiryReport />
            </Suspense>
          </TabPanel>
          <TabPanel value="5">
            <Suspense fallback={<Skelton />}>
              <FoodFacilityVessels />
            </Suspense>
          </TabPanel>
          <TabPanel value="6">
            <Suspense fallback={<Skelton />}>
              <FeedBackReport />
            </Suspense>
          </TabPanel>
          <TabPanel value="7">
            <Suspense fallback={<Skelton />}>
              <EmployeeReport />
            </Suspense>
          </TabPanel>
          <TabPanel value="8">
            <Suspense fallback={<Skelton />}>
              <CancelledBookings />
            </Suspense>
          </TabPanel>
          <TabPanel value="9">
            <Suspense fallback={<Skelton />}>
              <CooksReport />
            </Suspense>
          </TabPanel>
          <TabPanel value="10">
            <Suspense fallback={<Skelton />}>
              <StaffsReport />
            </Suspense>
          </TabPanel>
        </TabContext>
      </Box>
    </Card>
  }
  </>
  );
}

export default Reports;