import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Master from "./pages/Master/Master";
import Booking from "./pages/booking/Booking";
import Payment from "./pages/Payment/Payment";
import InvoiceView from "./pages/booking/components/InvoiceView";
import Reports from "./pages/Reports/Reports";
import UpcomingEvents from "./pages/Upcoming/UpcomingEvents";
import FeedBack from "./pages/FeedBack";
import ReceiptHistory from "./pages/Payment/ReceiptHistory";
import InVoicePreview from "./pages/booking/components/InVoicePreview";
import VesselReturn from "./pages/AmenityReturn/VesselReturn";
import PendingReceiptPrint from "./pages/Payment/PendingReceiptPrint";
import MarriageTable from "./pages/Marriage/MarriageTable";
// import CertficateGeneRate from "./pages/Marriage/CertficateGeneRate";
import CertficatePrint from "./pages/Marriage/CertficatePrint";
import LedgerTable from "./pages/CustomerLedger/LedgerTable";
import LedgerData from "./pages/CustomerLedger/LedgerData";
import CustDocument from "./pages/Reports/Components/CustDocument";
import FeedbackBy from "./pages/Reports/Components/FeedbackBy";
import SelectedStaffs from "./pages/Reports/Components/SelectedStaffs";
import VessalReturnCust from "./pages/Reports/Components/VessalReturnCust";
import NoAccess from "./pages/NoAccess";

// ----------------------------------------------------------------------
//const VesselReturn = lazy(() => import('./pages/AmenityReturn/VesselReturn'));

export default function Router() {
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "app", element: <DashboardApp /> },
        // { path: "user", element: <User /> },

        { path: "master", element: <Master /> },
        { path: "booking", element: <Booking /> },
        { path: "invoiceView", element: <InvoiceView /> },
        { path: "Receipts", element: <Payment /> },

        { path: "reports", element: <Reports /> },
        { path: "upcoming", element: <UpcomingEvents /> },
        { path: "vesselReturn", element: <VesselReturn /> },
        { path: "certificate", element: <MarriageTable /> },
        { path: "ledger", element: <LedgerTable /> },
        { path: "ledgerData", element: <LedgerData /> },
        { path: "customerDocument", element: <CustDocument /> },
        { path: "feedbackBy", element: <FeedbackBy /> },
        { path: "staffs", element: <SelectedStaffs /> },
        { path: "vessalreturn", element: <VessalReturnCust /> },
        { path: "noaccess", element: <NoAccess /> },
      ],
    },
    { path: "invoice", element: <InVoicePreview /> },
    { path: "generate", element: <CertficatePrint /> },
    { path: "paymentHistory", element: <ReceiptHistory /> },
    { path: "Paymentreceipt", element: <PendingReceiptPrint /> },

    {
      path: "login",
      element: <Login />,
    },
    {
      path: "register",
      element: <Register />,
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard/app" /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
    {
      path: "feedBack/:id",
      element: <FeedBack />,
    },
  ]);
}
