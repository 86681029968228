import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Switch,
  List,
  ListItem,
  ListItemText,
  Select,
  Divider,
  Box,
  Button,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import axios from "axios";

import { baseUrl } from "src/pages/config";
import "../../App.css";

// const listname = [
//   "master",
//   "booking",
//   "receipt",
//   "upcommingEvents",
//   "marriageCrtificate",
//   "customerLedger",
//   "amenityReturn",
//   "reports",
// ];
function UserPrivillage({setOpenDia}) {
  const navigate = useNavigate();
  const [staff, setStaff] = useState(null);
  const [first, setFirst] = useState(false);
  const [getStaff, setGetStaff] = useState([]);
  // const {keys,setKeys}
  const [element, setElement] = useState({
    master: false,
    booking: false,
    receipt: false,
    upcommingEvents: false,
    marriageCrtificate: false,
    customerLedger: false,
    amenityReturn: false,
    reports: false,
  });
  useEffect(() => {
    async function fetchData() {
      await axios
        .get(baseUrl + "/api/getEmployees")
        .then((res) => {
          setGetStaff(res.data);
        })
        .catch((err) => {
          if (err.response.status === 403)
            navigate("/login", { replace: true });
        });
    }

    fetchData();
  }, []);
  const handleUserChange = (event) => {
    setStaff(event.target.value);
    axios({
      method: "post",
      url: baseUrl + "/api/getPrivilageUser",
      data: { name: event.target.value },
    })
      .then((res) => {
        if (res.data.length > 0) {
          const jsonString = res.data[0].privilages;
          const decodedObject = JSON.parse(jsonString);
          const keys = Object.keys(decodedObject);
          const updatedElement = { ...element };
          keys.forEach((key) => {
            if (updatedElement.hasOwnProperty(key)) {
              updatedElement[key] = decodedObject[key];
            }
          });
          setElement(updatedElement);
        } else setFirst(true);
      })
      .catch((err) => {
        if (err.response.status === 403) navigate("/login", { replace: true });
      });
  };
  const handleChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.checked;

    const newData = { ...element };
    newData[fieldName] = fieldValue;
    setElement(newData);
  };

  const handleSubmit = () => {
    const data = { name: staff, privilages: element };
    if (!first) {
      axios({
        method: "put",
        url: baseUrl + "/api/updateUserPrivilage",
        data: data,
      })
        .then((res) => {})
        .catch((err) => {
          if (err.response.status === 403)
            navigate("/login", { replace: true });
        });
    } else {
      axios({
        method: "post",
        url: baseUrl + "/api/addUserPrivilage",
        data: data,
      })
        .then((res) => {})
        .catch((err) => {
          if (err.response.status === 403)
            navigate("/login", { replace: true });
        });
    }
    setOpenDia(false)
  };
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Users</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={staff}
          label="Users"
          onChange={handleUserChange}
        >
          {getStaff.map((getStaff) => (
            <MenuItem value={getStaff.Name} key={getStaff.Name}>
              {getStaff.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider />
      <List
        sx={{
          width: "100%",
          maxWidth: 500,
          bgcolor: "background.paper",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
        className="userPrivillage"
      >
        {Object.keys(element).map((key) => (
          <ListItem key={key}>
            <ListItemText
              id={`switch-list-label-${key}`}
              primary={key}
              sx={{ textTransform: "capitalize" }}
            />
            <Switch
              color="error"
              checked={element[key]}
              onChange={handleChange}
              name={key}
              inputProps={{ "aria-label": "controlled" }}
            />
          </ListItem>
        ))}
      </List>
      {staff !== null ? (
        <Box display="flex" justifyContent="flex-end">
          <Button
            sx={{ background: "purple" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </Box>
      ) : null}
      {/* */}
    </>
  );
}

export default UserPrivillage;
