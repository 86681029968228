import React, { useEffect, useState } from "react";
import { baseUrl } from "src/pages/config";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Card } from "@mui/material";
import NoData from "src/pages/NoData";

function CustDocument() {
  const { state } = useLocation();
  const [adhaar, setAdhaar] = useState(null);
  const [cookId, setCookId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      await axios({
        method: "post",
        url: baseUrl + "/api/getAdharCookByID",
        data: { cust_id: state.cust_id },
      })
        .then((res) => {
      
          setAdhaar(res.data[0].id_image);
          setCookId(res.data[0].cook_license);
        })
        .catch((err) => {
             if (err.response.status === 403) navigate("/login", { replace: true });
        });
    }
    getData();
  }, []);
  const PrintCust = (e) => {

    let printContents = document.getElementById("cust").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };
  const PrintCook = (e) => {

    let printContents = document.getElementById("cook").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };


  return (
    <div>
      <Card
        sx={{ p: 2 }}
        style={{
          display: "flex",
          justifyContent: "space-around",

          gap: "10px",
        }}
      >
        <>
          <div>
            <h4 style={{ padding: "1rem" }}>Customer Document</h4>
            {adhaar == null ? (
              <NoData />
            ) : (
              <div id="cust">
                <img
                  style={{ width: "25rem", objectFit: "cover" }}
                  src={adhaar}
                  alt=""
                />
              </div>
            )}
            <Button variant="contained" size="small" onClick={PrintCust}>
              {" "}
              Print
            </Button>
          </div>
        </>
          <div>
            <h4 style={{ padding: "1rem" }}>Cook Document</h4>
        {cookId == null ? (
          <NoData />
        ) : (
            <div id="cook">
              <img
                style={{ width: "25rem", objectFit: "cover" }}
                src={cookId}
                alt=""
              />
            </div>
              )}
            <Button variant="contained" size="small" onClick={PrintCook}>
              {" "}
              Print
            </Button>
          </div>
      </Card>
    </div>
  );
}

export default CustDocument;
