import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider } from "../../../components/hook-form";
import axios from "axios";
import { baseUrl } from "../../../pages/config";

export default function LoginForm() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (e) => {
    e.preventDefault();

    axios
      .post(baseUrl + "/api/login", {
        username: userName,
        password: password,
      })
      .then(function (response) {
        if (response.data.data === "success") {
          navigate("/", { replace: true });
          axios({
            method: "post",
            url: baseUrl + "/api/getPrivilageUser",
            data: { name: userName },
          })
            .then((res) => {
              const jsonString = res.data[0].privilages;
              const privObject = JSON.parse(jsonString);

              const trueKeys = Object.keys(privObject).filter(
                (key) => privObject[key]
              );
              const sortedKeys = trueKeys.sort();
              localStorage.setItem("acc", JSON.stringify(sortedKeys));
            })
            .catch((err) => {
              if (err.response.status === 403)
                navigate("/login", { replace: true });
            });
        } else {
          setOpen(true);
        }
      })
      .catch(function (error) {});
    //navigate('/dashboard', { replace: true });
  };
  useEffect(() => {
    localStorage.setItem("userName", JSON.stringify(userName));
  }, [userName]);
  const handleUserName = (e) => {
    setUserName(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <FormProvider methods={methods}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Login Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The password or user name that you've entered is incorrect.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            autoFocus
            color="error"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Stack spacing={3}>
        <TextField
          name="name"
          type="text"
          label="User Name"
          onChange={handleUserName}
          value={userName}
          required
        />

        <TextField
          name="password"
          type="password"
          label="Password"
          onChange={handlePassword}
          required
          value={password}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      ></Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        onClick={onSubmit}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
