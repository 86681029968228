import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "src/pages/config";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TextField } from "@mui/material";

function PaymentHistory() {
  const navigate = useNavigate();
  const [pHistory, setHistory] = useState([]);
  const [startDate, setStartDate] = React.useState(dayjs());
  const [endDate, setEndDate] = React.useState(dayjs());

  const startDateChange = (newValue) => {
    setStartDate(newValue);
  };
  const endDateChange = (newValue) => {
    setEndDate(newValue);
  };
  useEffect(() => {
    async function PaymentHistory() {
      await axios
        .get(baseUrl + "/api/getPaymentHistory")
        .then((res) => {
          setHistory(res.data);
        })
        .catch((err) => {
             if (err.response.status === 403) navigate("/login", { replace: true });
        });
    }
    PaymentHistory();
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "SiNo",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
      width: 70,
    },
    { field: "cust_id", headerName: "Booking ID", width: 130 },
    { field: "name", headerName: "Name", width: 220 },
    {
      field: "Date1",
      headerName: "Date",
      type: "date",
      width: 150,
    },
    {
      field: "trans_type",
      headerName: "Transaction Mode",
      type: "text",
      width: 150,
    },
    {
      field: "Amount",
      headerName: "Paid",
      type: "number",
      width: 170,
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <Button
              size="small"
              variant="contained"
              onClick={(e) => handlePassRow(e)}
            >
              Open
            </Button>
          </>
        );
      },
    },
  ];

  const routeChange = (datas) => {
    let path = "/paymentHistory";
    const data = { state: datas.SiNO };
    navigate(path, data);
  };
  const handlePassRow = (e) => {
    routeChange(e.row);
  };
  const historyByDate = () => {
    const dates = { "sDate": startDate, "eDate": endDate };

    axios({
      method: "post",
      url: baseUrl + "/api/getPaymentHistoryByDate",
      data: dates,
    }).then((res) => {
      setHistory(res.data);
    }).catch((err) => {
         if (err.response.status === 403) navigate("/login", { replace: true });
    });
  };
  // const rows = [{ id: 1, cust_id: 4555 }];
  return (
    <>
      <p style={{ padding: "1rem" }}>Choose date</p>
      <div className="dateSel" style={{ padding: "1rem" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            label="Start Date"
            inputFormat="DD/MM/YYYY"
            value={startDate}
            onChange={startDateChange}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <MobileDatePicker
            label="End Date"
            inputFormat="DD/MM/YYYY"
            value={endDate}
            onChange={endDateChange}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <Button variant="outlined" size="small" onClick={historyByDate}>
            Search
          </Button>
        </LocalizationProvider>
      </div>
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGrid
          rows={pHistory}
          columns={columns}
          onRowClick={(e) => handlePassRow(e)}
        />
      </div>
      <p style={{ padding: "10px" }}>
        {" "}
        <b>
          Total Amount :{" "}
          {Math.round(pHistory.reduce((a, v) => (a = a + v.Amount), 0))}
        </b>
      </p>
    </>
  );
}

export default PaymentHistory;
