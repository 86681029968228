import React from "react";
import ReactToPrint from "react-to-print";
import HistoryPrint from "./HistoryPrint";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
// import "./Payment.css";

class ComponentsToPrint extends React.Component {
  render() {
    return <HistoryPrint />;
  }
}

class Example extends React.Component {
  render() {
    return (
      <div className="printInfo">
        <div className="actionsRec">
          <Link to="/dashboard/Receipts">
            <CloseIcon />
          </Link>
        </div>
        <ComponentsToPrint ref={(el) => (this.componentRef = el)} />
        <ReactToPrint
          trigger={() => (
            <div className="actionsRec">
              <Button variant="contained" color="primary">
                Print
              </Button>
            </div>
          )}
          content={() => this.componentRef}
        />
      </div>
    );
  }
}

export default Example;
