import React from "react";
import { Divider, Box } from "@mui/material";
import "./AddEventModal.css";
import { ToWords } from "to-words";

function ModalTotal({ subTotal, tax, taxType, discTotal, netTotal, taxTotal }) {
  const toWords = new ToWords();

  // let words = toWords.convert(subTotal, { currency: true });
  // words = Four Hundred Fifty Two Rupees Only
  let words;
if(tax>0){

   words = toWords.convert(
    taxType !== "none"
    ? subTotal - discTotal + ((subTotal - discTotal) / 100) * tax
    : subTotal - discTotal,
    { currency: true }
    );
  }else(
     words = toWords.convert(
      taxType !== "none"
      ? (subTotal - discTotal )+ taxTotal
      : subTotal - discTotal,
      { currency: true }
      )
  )
  return (
    <div>
      <Box>
        <Divider />
        <div className="totalamt">
          <div>
            {" "}
            <h3 style={{ fontWeight: 500 }}>{words} </h3>
          </div>

          {tax > 0 ? (
            <div className="amountSec">
              <h3>Subtotal : {Math.round(subTotal)} </h3>
              <h3>Discount : {discTotal}</h3>
              {taxType === "CGST" ? (
                <>
                  <h3>
                    {" "}
                    CGST :{" "}
                    {parseFloat(
                      (((subTotal - discTotal) / 100) * tax) / 2
                    ).toFixed(2)}{" "}
                  </h3>{" "}
                  <h3>
                    {" "}
                    SGST :{" "}
                    {parseFloat(
                      (((subTotal - discTotal) / 100) * tax) / 2
                    ).toFixed(2)}{" "}
                  </h3>
                </>
              ) : taxType === "IGST" ? (
                <h3>
                  {" "}
                  IGST :{" "}
                  {parseFloat(((subTotal - discTotal) / 100) * tax).toFixed(
                    2
                  )}{" "}
                </h3>
              ) : taxType === "VAT" ? (
                <h3>
                  {" "}
                  TAX :{" "}
                  {parseFloat(((subTotal - discTotal) / 100) * tax).toFixed(
                    2
                  )}{" "}
                </h3>
              ) : (
                ""
              )}

              <h3>
                Net Amount :{" "}
                <span style={{ color: "green", fontWeight: 800 }}>
                  {" "}
                  ₹{" "}
                  {Math.round(
                    taxType !== "none"
                      ? subTotal -
                          discTotal +
                          ((subTotal - discTotal) / 100) * tax
                      : subTotal - discTotal
                  )}
                </span>
              </h3>
            </div>
          ) : (
            <div className="amountSec">
              <h3>Subtotal : {Math.round(subTotal)} </h3>
              <h3>Discount : {discTotal}</h3>
              {taxType === "CGST" ? (
                <>
                  <h3> CGST :{parseFloat(taxTotal / 2).toFixed(2)}</h3>{" "}
                  <h3> SGST : {parseFloat(taxTotal / 2).toFixed(2)} </h3>
                </>
              ) : taxType === "IGST" ? (
                <h3> IGST : {parseFloat(taxTotal).toFixed(2)} </h3>
              ) : taxType === "VAT" ? (
                <h3> TAX : {parseFloat(taxTotal).toFixed(2)} </h3>
              ) : (
                ""
              )}

              <h3>
                Net Amount :{" "}
                <span style={{ color: "green", fontWeight: 800 }}>
                  {" "}
                  ₹{" "}
                  {Math.round(
                    taxType !== "none"
                      ? subTotal - discTotal + taxTotal
                      : subTotal - discTotal
                  )}
                </span>
              </h3>
            </div>
          )}
        </div>
      </Box>
    </div>
  );
}

export default ModalTotal;
