import React from "react";
import { baseUrl } from "src/pages/config";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import "./css/Certficate.css";
import NoData from "../NoData";

function CertficateGeneRate() {
  const { state } = useLocation();
  const [data, setData] = useState({});
  const [comInfo, setComInfo] = useState([]);


  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      await axios({
        method: "post",
        url: baseUrl + "/api/getMarriageDataById",
        data: { book_id: state.cust_id },
      })
        .then((res) => {
          setData(res.data[0]);
        })
        .catch((err) => {
             if (err.response.status === 403) navigate("/login", { replace: true });
        });
    }
    async function getCompany() {
      await axios.get(baseUrl + "/api/company").then((res) => {
        setComInfo(res.data[0]);
      });
    }

    getCompany();
    getData();
  }, [0]);

  return (
    <>
      {data.bride_add==null? 
      <NoData/>:
        <>
        <div className="certficateBody">
          <div className="company">
            <div
              className="logoM"
              style={{ width: "100%", textAlign: "center" }}
            >
              {" "}
              <img
                style={{ width: "7rem", textAlign: "center" }}
                src="/static/CompanyLogo.png"
              />
              <p>{comInfo.Add1}</p>
              <p>{comInfo.Place}</p>
              <p>
                {comInfo.Ph}, {comInfo.Country}
              </p>
            </div>
          </div>
          <hr />
          <p className="headP">To Whomsoever it may concern</p>
          <div className="certFormat">
            <p>
              {" "}
              &#160; &#160; &#160; &#160; &#160; This is to confirm that the
              wedding of Sri{" "}
              <span>
                <b>{data.groom_add}</b>
              </span>{" "}
              with Smt{" "}
              <span>
                <b>{data.bride_add}</b>
              </span>{" "}
              was conducted at <span>{comInfo.Name}</span> on{" "}
              <span>{data.event_date} </span>{" "}
            </p>
          </div>
          <div className="footerC">
            <p>{comInfo.Name}</p>

            <br />
            <br />
            <br />
            <p>Manager</p>
          </div>
        </div>
      </>
        }
    </>
  );
}

export default CertficateGeneRate;
