// routes
import CheckConnection from "./pages/CheckConnection";
import Router from "./routes";
// import "./App.css";
// theme
import ThemeProvider from "./theme";

// ----------------------------------------------------------------------

export default function App() {
  return (
    // <CheckConnection>
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  //  </CheckConnection> 
  );
}
