import React, { useState, useEffect, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import {
  Card,
  Typography,
  IconButton,
  Toolbar,
  AppBar,
  Dialog,
  Button,
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import { baseUrl } from "../config.js";
import interactionPlugin from "@fullcalendar/interaction";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PreviewIcon from "@mui/icons-material/Preview";
import "./Booking.css";
import dayjs from "dayjs";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Label from "src/components/Label";
import Skelton from "../Skelton.js";
import SmallSkelton from "../SmallSkelton.js";
import MuiAlert from "@mui/material/Alert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoIcon from "@mui/icons-material/Info";
import DuplicateEventModal from "./components/DuplicateEventModal.js";
import CancelForm from "./components/CancelForm.js";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AddStaff from "./components/AddStaff.js";
import NoData from "../NoData.js";
import NoAccess from "../NoAccess.js";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AddEventModal = lazy(() => import("./components/AddEventModal"));
const EditEventModal = lazy(() => import("./components/EditEventModal.js"));
const WhatsappShare = lazy(() => import("./components/WhatsappShare.js"));

// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       {/* <GridToolbarExport /> */}
//       <GridToolbarFilterButton />
//     </GridToolbarContainer>
//   );
// }

const Booking = () => {
  const pName = "booking";
  const [access, setAccess] = useState(true);
  const [events, setEvent] = useState([]);
  const [bookingMaster, setBookingMaster] = useState();
  const [selectDate, setSelectDate] = useState({
    start: null,
    end: null,
  });
  const [startStr, setStartStr] = useState();
  const [endStr, setEndStr] = useState();
  const [open, setOpen] = useState(false);
  const [printType, setPrintType] = useState("BOOKING");
  const [editModal, setEditModal] = useState(false);
  const [editBookInfo, setEditBookInfo] = useState([]);
  const [editCookInfo, setEditCookInfo] = useState({});
  const [whatsappOpen, setwhatsappOpen] = useState(false);
  const [staffModal, setStaffModal] = useState(false);
  const [docuOpen, setDocuOpen] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState(null);
  const [openDel, setOpenDel] = useState(false);
  const [delId, setDelId] = useState(null);
  const [delBookId, setDelBookId] = useState(null);
  const [customerProof, setCustomerProof] = useState(null);
  const [cookProof, setCookProof] = useState(null);
  const [idForProof, setIdForProof] = useState(null);
  const navigate = useNavigate();
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [cancelDatas, setCancelDatas] = useState({});
  const [staffBook, setStaffBook] = useState();
  const [snack, setSnack] = useState({
    typ: "",
    message: "",
  });
  const [duplicateOpen, setDuplicateOpen] = useState(false);

  useEffect(() => {
    async function getBookings() {
      const items = JSON.parse(localStorage.getItem("acc"));

      if (items.includes(pName)) {
        setAccess(true);
      } else {
        setAccess(false);
      }
      await axios
        .get(baseUrl + "/api/getBookings")
        .then((res) => {
          let evts = res.data;

          setEvent(res.data);
        })
        .catch((err) => {
          if (err.response.status === 403)
            navigate("/login", { replace: true });
        });
    }
    getBookings();
  }, []);

  const handleSelect = async (eventInfo) => {
    var date = dayjs().format("YYYY-MM-DD");
    var end_date = dayjs().format("YYYY-MM-DD");
    if ((eventInfo.startStr >= date, eventInfo.endStr >= date)) {
      const { start, end } = eventInfo;

      await axios
        .get(baseUrl + "/api/getBookingMaster")
        .then((res) => {
          setBookingMaster(res.data);
        })

        .catch((err) => {
          if (err.response.status === 403)
            navigate("/login", { replace: true });
        });
      setStartStr(start);
      setEndStr(end);
      // return
      setOpen(true);
    }
  };
  const handleClicDelkOpen = (id, book_id) => {
    setDelId(id);
    setDelBookId(book_id);
    setOpenDel(true);
  };

  const handleDelClose = () => {
    setOpenDel(false);
  };
  const columns = [
    {
      field: "#",
      headerName: "SiNo",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
      width: 60,
    },
    { field: "book_date", headerName: "Date", width: 110 },
    { field: "cust_id", headerName: "Booking ID", width: 90 },
    { field: "name", headerName: "Customer Name", width: 130 },
    { field: "event_typ", headerName: "Event Type", width: 110 },
    {
      field: "start",
      headerName: "Event Start",
      width: 100,
      renderCell: (params) => {
        return <>{dayjs(params.row.start).format("DD-MM-YYYY")}</>;
      },
    },
    {
      field: "end",
      headerName: "Event End",
      width: 100,
      renderCell: (params) => {
        return <>{dayjs(params.row.end).format("DD-MM-YYYY")}</>;
      },
    },
    { field: "contact1", headerName: "Contact", width: 130 },
    { field: "staff", headerName: "Staff Name", width: 120 },
    {
      field: "typ",
      headerName: "Type",
      width: 90,
      renderCell: (params) => {
        return (
          <>
            {params.row.typ === "BOOKING" ? (
              <Label color="success">{params.row.typ}</Label>
            ) : (
              <Label color="warning">{params.row.typ}</Label>
            )}
          </>
        );
      },
    },
    //
    {
      field: "action",
      headerName: "Action",
      width: 260,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => handleEditClickOpen(params.row.id, "EDIT")}
              >
                <BorderColorIcon color="primary" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Duplicate">
              <IconButton
                onClick={() => handleEditClickOpen(params.row.id, "DUPLICATE")}
              >
                <ContentCopyIcon color="light" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Cancel">
              <IconButton
                aria-label="cancel"
                onClick={() =>
                  handleClicDelkOpen(params.row.id, params.row.cust_id)
                }
              >
                <CancelIcon color="error" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Print">
              <IconButton onClick={() => handlePrintClickOpen(params.row.id)}>
                <PreviewIcon color="dark" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Staff">
              <IconButton onClick={() => handleAddStaff(params.row)}>
                <PersonAddAltIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="More">
              <IconButton
                onClick={(row) => handleDocuClickOpen(params.row.cust_id)}
              >
                <MoreVertIcon style={{ color: "black" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share">
              <IconButton
                onClick={() => handleWhatsappClickOpen(params.row.contact1)}
              >
                <WhatsAppIcon color="success" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleAddStaff = (row) => {
    setStaffBook(row);

    setStaffModal(true);
  };
  const handleStaffModal = () => {
    setStaffModal(false);
  };
  /* ---------- Delete booking---------- */
  const deleteBooking = (eventId) => {
    const newEvent = [...events];
    const index = events.findIndex((events) => events.id === eventId);
    handleDelClose();

    axios({
      method: "post",
      url: baseUrl + "/api/addCancelledDetail",
      data: cancelDatas,
    })
      .then((res) => {
        if (res.data) {
          axios({
            method: "put",
            url: baseUrl + "/api/deleteBooking",
            data: { id: eventId },
          })
            .then((res) => {
              if (res.data) {
                newEvent.splice(index, 1);
                setEvent(newEvent);
                setSnack({
                  typ: "success",
                  message: "canceled successfully",
                });
                handleSnackClick();
              }
            })
            .catch((err) => {
              if (err.response.status === 403)
                navigate("/login", { replace: true });
            });
        } else {
          window.alert("failed");
          setSnack({
            typ: "error",
            message: "Something error",
          });
          handleSnackClick();
        }
      })
      .catch((err) => {
        if (err.response.status === 403) navigate("/login", { replace: true });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* ---------- Label colour condition---------- */

  const handleEditClickOpen = (rowId, typ) => {
    if (typ === "DUPLICATE") {
      setDuplicateOpen(true);
    } else {
      setDuplicateOpen(false);
    }
    async function editData() {
      const editEvent = [...events];
      const index = events.findIndex((events) => events.id === rowId);
      axios
        .get(baseUrl + "/api/getBookingMaster")
        .then((res) => {
          setBookingMaster(res.data);
        })
        .catch((err) => {
          if (err.response.status === 403)
            navigate("/login", { replace: true });
        });

      await axios({
        method: "post",
        url: baseUrl + "/api/getBookingById",
        data: { id: rowId },
      })
        .then((res) => {
          setEditBookInfo(res.data);

          setEditCookInfo(JSON.parse(res.data[0][0].cook_info));
        })
        .catch((err) => {
          if (err.response.status === 403)
            navigate("/login", { replace: true });
        });

      setEditModal(true);
    }

    editData();
  };

  const handleEditClose = () => {
    setEditModal(false);
  };

  const routeChange = (pid, typ) => {
    let path = "/invoice";
    const data = { state: { id: pid, typ: typ } };
    navigate(path, data);
  };

  const handlePrintClickOpen = async (rowId) => {
    routeChange(rowId, printType);
  };

  const handleWhatsappClickOpen = async (contact) => {
    setWhatsappNumber(contact);
    setwhatsappOpen(true);
  };

  const handleModalClose = () => {
    setwhatsappOpen(false);
  };

  const handleShareToWp = () => {
    setwhatsappOpen(false);
    window.open("https://api.whatsapp.com/send?phone=" + whatsappNumber);
  };

  const childToParent = (childdata) => {
    setWhatsappNumber(childdata);
  };
  const cancelData = (cancelData) => {
    setCancelDatas(cancelData);
  };
  const handlePrintType = (e) => {
    setPrintType(e.target.value);
  };

  const handleDocuClickClose = () => {
    setDocuOpen(false);
  };
  const handleDocuClickOpen = (row) => {
    setIdForProof(row);

    setDocuOpen(true);
  };

  const uploadFile = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", customerProof);
    data.append("book_id", idForProof);
    axios
      .post(baseUrl + "/uploadCustomerDoc", data) //uploadCustomerDoc    uploadCookDoc
      .then((res) => {
        if (res.data == true) {
          setSnack({
            typ: "success",
            message: "Added successfully",
          });
          handleSnackClick();
        } else {
          setSnack({
            typ: "error",
            message: "Something error",
          });
          handleSnackClick();
        }
      });
  };

  const uploadcookFile = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", cookProof);
    data.append("book_id", idForProof);
    axios
      .post(baseUrl + "/uploadCookDoc", data) //uploadCustomerDoc    uploadCookDoc
      .then((res) => {
        if (res.data == true) {
          setSnack({
            typ: "success",
            message: "Added successfully",
          });
          handleSnackClick();
        } else {
          setSnack({
            typ: "error",
            message: "Something error",
          });
          handleSnackClick();
        }
      });
  };
  const getCustProof = (e) => {
    setCustomerProof(e.target.files[0]);
  };
  const getCookProof = (e) => {
    setCookProof(e.target.files[0]);
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  return (
    <>
      <Dialog
        open={openDel}
        onClose={handleDelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CancelForm bookingId={delBookId} cancelData={cancelData} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDelClose} variant="contained">
            No
          </Button>
          <Button
            type="submit"
            color="error"
            variant="contained"
            onClick={() => deleteBooking(delId)}
          >
            Yes
          </Button>
          {/* <button onClick={cancelDetails}></button> */}
        </DialogActions>
      </Dialog>

      <Dialog
        open={whatsappOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{" Whatsapp Share?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Suspense fallback={<SmallSkelton />}>
              <WhatsappShare
                handleWhatsappClickOpen={whatsappNumber}
                childToParent={childToParent}
              />
            </Suspense>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            onClick={handleModalClose}
          >
            Close
          </Button>
          <Button autoFocus variant="contained" onClick={handleShareToWp}>
            Share
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={staffModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleStaffModal}
      >
        <DialogTitle id="alert-dialog-title">
          {" Add house keeping staff"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Suspense fallback={<SmallSkelton />}>
              <AddStaff staffBook={staffBook} setStaffModal={setStaffModal} />
            </Suspense>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <br />
      {/* ---------- Booking Modal---------- */}
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Booking Details
            </Typography>
            <Button autoFocus variant="contained" onClick={handleClose}>
              Back
            </Button>
          </Toolbar>
        </AppBar>

        <Suspense fallback={<Skelton />}>
          <AddEventModal
            bookingMaster={bookingMaster}
            modalClose={handleClose}
            eventTable={setEvent}
            eventInfo={selectDate}
            startStr={startStr}
            endStr={endStr}
          />
        </Suspense>
      </Dialog>
      <br />
      <Dialog fullScreen open={editModal} onClose={handleEditClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Booking Details
            </Typography>
            <Button autoFocus variant="contained" onClick={handleEditClose}>
              Back
            </Button>
          </Toolbar>
        </AppBar>

        <Suspense fallback={<Skelton />}>
          {duplicateOpen !== true ? (
            <EditEventModal
              editBookInfo={editBookInfo}
              bookingMaster={bookingMaster}
              editCookInfo={editCookInfo}
              setEditModal={setEditModal}
            />
          ) : (
            <DuplicateEventModal
              editBookInfo={editBookInfo}
              bookingMaster={bookingMaster}
              editCookInfo={editCookInfo}
              setEditModal={setEditModal}
            />
          )}
        </Suspense>
      </Dialog>
      <Snackbar
        style={{ bottom: "24px", left: "auto", right: "24px" }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        //key={vertical + horizontal}
      >
        <Alert
          onClose={handleSnackClose}
          severity={snack.typ}
          sx={{ width: "100%" }}
        >
          {snack.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={docuOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{" Upload Document"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <p style={{ fontSize: "12px" }}> Upload Customer Adhaar</p>
              <Stack direction="row" spacing={3}>
                <form>
                  <input
                    accept="image/*"
                    onChange={getCustProof}
                    multiple
                    type="file"
                  />

                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    onClick={uploadFile}
                  >
                    Save
                  </Button>
                </form>
              </Stack>
            </div>
            <div>
              <p style={{ fontSize: "12px" }}> Upload Cook license</p>
              <Stack direction="row" spacing={2}>
                <form>
                  <input
                    accept="image/*"
                    onChange={getCookProof}
                    multiple
                    type="file"
                  />

                  <Button
                    size="small"
                    variant="contained"
                    onClick={uploadcookFile}
                    type="submit"
                  >
                    Save
                  </Button>
                </form>
              </Stack>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" type="submit" onClick={handleDocuClickClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* ---------- Booking Calender---------- */}
      {access === false ? (
        <NoAccess />
      ) : (
        <>
          {" "}
          <Card>
            <div className="indication">
              <p>
                {" "}
                <div className="bookngLabel"></div>Booking
              </p>
              <p>
                {" "}
                <div className="enqLabel"></div>Enquiry
              </p>
            </div>

            <FullCalendar
              selectable
              elevation={0}
              plugins={[dayGridPlugin, interactionPlugin]}
              select={handleSelect}
              initialView="dayGridMonth"
              weekends
              showNonCurrentDates={false}
              eventContent={renderEventContent}
              events={events}
              validRange
            />
            <Box sx={{ pb: 3 }}>
              <table className="calFoot">
                <tfoot>
                  <tr>
                    <td>Sun</td>
                    <td>Mon</td>
                    <td>Tue</td>
                    <td>Wed</td>
                    <td>Thu</td>
                    <td>Fri</td>
                    <td>Sat</td>
                  </tr>
                </tfoot>
              </table>
            </Box>
          </Card>
          <br />
          <hr />
          <h2 style={{ paddingTop: "10" }}>Bookings</h2>
          <br />
          <Box sx={{ minWidth: 120 }}></Box>
          {/* ---------- Booking Table---------- */}
          <Card>
            <Box style={{ textAlign: "right" }}>
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small">Print Type</InputLabel>
                <Select
                  style={{ textAlign: "center" }}
                  labelId="demo-select-small"
                  id="demo-select-small"
                  label="Print Type"
                  value={printType}
                  onChange={handlePrintType}
                >
                  <MenuItem value={"BOOKING"}>Booking</MenuItem>
                  <MenuItem value={"INVOICE"}>Invoice</MenuItem>
                  <MenuItem value={"QUOTATION"}>Quotation</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ height: "95vh", width: "100%" }}>
              <DataGrid
                // components={{ Toolbar: CustomToolbar }}
                rows={events}
                columns={columns}
                pageSize={20}
                
              />
            </Box>
          </Card>
        </>
      )}
    </>
  );
};
{
  /* ---------- Table content rendring controll---------- */
}

function renderEventContent(eventInfo) {
  const { event_typ, typ, name, cust_id } =
    eventInfo?.event?.extendedProps || {};
  let isBooking = false;

  if (typ === "BOOKING") {
    isBooking = true;
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const Handleshow = () => {
    axios({
      method: "post",
      url: baseUrl + "/api/getBookingByBookId",
      data: { id: cust_id },
    }).then((res) => {
      const facility = res.data[1];
      var alertBody = "";
      alertBody +=
        "Name: " +
        res.data[0][0].name +
        "\nEvent Date: " +
        res.data[0][0].event_date +
        "       " +
        dayjs("01/01/2022 " + res.data[0][0].start_time).format("hh:mm:ss A") +
        " - " +
        dayjs("01/01/2022 " + res.data[0][0].end_time).format("hh:mm:ss A") +
        "\n\n";
      if (facility.length > 0) {
        alertBody += "Facilities:- " + "\n";
        for (var i = 0; i < facility.length; i++) {
          alertBody += i + 1 + "- " + facility[i].content_name + "\n";
        }
      }
      alert(alertBody);
    });
  };

  return (
    <>
      <div
        className={isBooking ? "bookingLabel" : "enquiryLabel"}
        id="bookLabel"
        onClick={Handleshow}
      >
        <p className="cuName ">{name}</p>
        <p className="eventTyp">{event_typ}</p>
      </div>
    </>
  );
}
export default Booking;
