// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'master',
    path: '/dashboard/master',
    icon: getIcon('eva:people-fill'),
  },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon('eva:people-fill'),
  // },
  {
    title: 'Booking',
    path: '/dashboard/booking',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Receipts',
    path: '/dashboard/Receipts',
    icon: getIcon('eva:credit-card-fill'),
  },
  {
    title: 'Upcoming Events',
    path: '/dashboard/upcoming',
    icon: getIcon('eva:bell-fill'),
  },
  {
    title: 'Marriage Certificate',
    path: '/dashboard/certificate',
    icon: getIcon('ant-design:safety-certificate-filled'),
  },
  {
    title: 'Customer Ledger',
    path: '/dashboard/ledger',
    icon: getIcon('fluent-emoji-high-contrast:ledger'),
  },
  {
    title: 'Amenity Return',
    path: '/dashboard/vesselReturn',
    icon: getIcon('mdi:clipboard-arrow-down'),
  },
  {
    title: 'reports',
    path: '/dashboard/reports',
    icon: getIcon('eva:file-text-fill'),
  },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
